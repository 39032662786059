const Items = [

  {
    id: 1,
    mainImage: "https://i.ibb.co/dMjKxPZ/1.png",
    img: [
      "https://i.ibb.co/dMjKxPZ/1.png",
      "https://i.ibb.co/PZHG3Fw/2.jpg",
      "https://i.ibb.co/sw8BqK2/3.jpg",
      "https://i.ibb.co/hYBGrtK/4.jpg",
      "https://i.ibb.co/VVyDzmy/5.jpg",
      "https://i.ibb.co/D4XcKF0/6.png",
      "https://i.ibb.co/87BR8TS/7.png",
    ],
    features: [
      "Home",
      "About",
      "Package",
      "Contact",
      "A user can order through the Admin for Website services, Mobile Applications, Branding & Creative Content, as well as Internet Marketing & Advertising.",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "TailwindCSS",
      "React Leaflet",
      "React Hooks",
      "React icons",
      "Email JS",
      "Framer Motion",
    ],
    title: "Msn Production",
    description:
      "PT. MULIA SEJATI NUSANTARA Established on June 24, 2019, a limited liability company engaged in supplier & contractor services. Then on January 22 2022 PT. MULIA SEJATI NUSANTARA Opening a new business branch in the Technology Sector which includes Website Creation, Mobile Applications, Branding, Creative Content, Digital Marketing & Advertising. This branch is called Mulia Sejati Nusantara Production or in short MSN PRODUCTION.",
    category: "business",
    liveLink: "https://msnproduction.com/",
    codeLink: "https://github.com/masterdev0218/msnproduction",
  },
  {
    id: 2,
    mainImage: "https://i.ibb.co/PYZgRT0/portfolio1.png",
    img: [
      "https://i.ibb.co/PYZgRT0/portfolio1.png",
      "https://i.ibb.co/MhCswmj/portfolio2.png",
      "https://i.ibb.co/jHNgbwR/portfolio3.png",
      "https://i.ibb.co/HNxd7Qz/portfolio4.png",
      "https://i.ibb.co/zJCVdw5/portfolio5.png",
      "https://i.ibb.co/ChnPLqF/portfolio6.png",
      "https://i.ibb.co/HhjVxPL/portfolio7.png"
    ],
    features: [
      "User registration and login",
      "Authentication using JWT Tokens",
      "Story searching and pagination",
      "CRUD operations (Story create, read, update and delete)",
      "Upload user ımages and story ımages to the server",
      "Liking stories and adding stories to the Reading list",
      "Commenting on the story",
      "Skeleton loading effect",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "Axios",
      "CSS",
      "UUID",
      "NodeJS",
      "ExpressJS",
      "MongoDB",
      "Mongoose",
      "Express Asynsc Handler",
      "Json Web Token",
      "Bcryptjs",
      "Nodemailer",
      "Dontenv",
      "Multer",
      "Slugify",
      "Cors",
    ],
    title: "shofy-Jewelry-ecommerce",
    description:
      "Fullstack open source blogging application made with MongoDB, Express, React & Nodejs (MERN)",
    category: "personal",
    liveLink: "https://shofy-jewelry-ecommerce.vercel.app",
    codeLink: "https://github.com/masterdev0218/shofy-Jewelry-ecommerce-client",
  },

  {
    id: 3,
    mainImage: "https://i.ibb.co/71M4H44/img-1-lcun2a.png",
    img: [
      "https://i.ibb.co/71M4H44/img-1-lcun2a.png",
      "https://i.ibb.co/0nHVsBc/img-2-ar1fex.png",
      "https://i.ibb.co/LgxDR7d/img-3-qpebgb.png",
      "https://i.ibb.co/cJ6gQzQ/img-4-yadwtg.png",
      "https://i.ibb.co/9wF8S1v/img-6-aaq4yf.png",
      "https://i.ibb.co/6YXRcJf/img-7-twij3j.png",
      "https://i.ibb.co/TM7xRKY/img-8-njclct.png",
      "https://i.ibb.co/Bjjw8Tt/img-10-yag7bj.png",
      "https://i.ibb.co/Wcq5DSt/img-9-zg1jfe.png",
    ],
    features: [
      "User registration and login",
      "Authentication using JWT Tokens",
      "Add Favorites",
      "Delete Favorites",
      "Leave a Reviews",
      "Delete Reviews",
      "Password Update",
      "Search Live",
      "Watch the trailer on Youtube",
      "404 Page and many more",
      "Skeleton loading effect",
      "DarkMode",
      "DarkMode",
      "Responsive Design",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Router Dom",
      "Axios",
      "Material UI",
      "CK Editor",
      "Formik",
      "React Redux",
      "React Toastify",
      "Swiper",
      "NodeJS",
      "ExpressJS",
      "Mongoose",
      "Json Web Token",
      "Cookie Parser",
      "Cors",
      "Dontenv",
      "Express Validator",
      "Nodemon",
      "MongoDB",
      "TMDB API",
    ],
    title: "BertFlix",
    description:
      "Fullstack open source movie application made with MongoDB, Express, React & Nodejs (MERN)",
    category: "personal",
    liveLink: "https://bertflix.vercel.app/",
    codeLink: "https://github.com/masterdev0218/mern-movie",
  },
  {
    id: 4,
    mainImage: "https://i.ibb.co/tMCcx79/256913233-e7998109-d53d-4c93-80eb-f6e9c05f44b4.png",
    img: [
      "https://i.ibb.co/tMCcx79/256913233-e7998109-d53d-4c93-80eb-f6e9c05f44b4.png",
      "https://i.ibb.co/SVNDBwX/256913222-a3f72509-8ca0-452b-b121-ff4ecf94580d.png",
      "https://i.ibb.co/Qd97LfJ/256913238-0c2859cb-2d56-447c-bd19-fabe707988aa.png",
      "https://i.ibb.co/PDS0MDZ/256913235-f7424cc9-2b63-49af-a2b7-37bd9e79e342.png",
      "https://i.ibb.co/84MFQps/256913239-ed0af932-ea78-44ac-ba91-48eb91449517.png",
      "https://i.ibb.co/BB7pJ8N/256913242-3efac0b3-b2d5-45ec-b82c-82b9b191ffd2.png",
      "https://i.ibb.co/kyZzMcy/256913246-5d6f9974-7b8e-4c12-a049-44368640dc62.png",
      "https://i.ibb.co/cyq4N4f/256913250-be9c67c6-376f-450a-9ba3-c968bd9ec063.png",
      "https://i.ibb.co/sbNVKf1/256913252-8ffef668-7cb1-4004-980c-d2463683ba3b.png",
      "https://i.ibb.co/RH2KnW1/256913255-8067acac-dc49-4f6e-a6aa-f4baae2fa8a5.png",
      "https://i.ibb.co/QNfCzrB/256913257-ecd09fb0-1f97-4856-ba4d-28170927e070.png",
      "https://i.ibb.co/3Ttb31K/256913259-ecdafe57-1b4a-4ddf-9802-7a4c5d3be141.png",
      "https://i.ibb.co/WpZFJm9/256913261-f5831815-3cd7-4f83-8193-12c16f859023.png",
      "https://i.ibb.co/947Z5rY/256913263-8539843c-39f1-4642-84b6-cb9a2a4b09b9.png"
    ],
    features: [
      "Login Admin",
      "Add employee",
      "Edit employee",
      "Remove employee",
      "Add position",
      "Edit position",
      "Remove position",
      "Display data, input attendance, edit attendance and delete employee absences",
      "Employee Salary Deduction Setting",
      "Display data, input salary data, edit salary data and delete employee salary data",
      "Print payroll reports, absences, and payslips",
      "Change admin and employee passwords",
      "Login Employees/Staff",
      "Print employees/staff salary reports from personal accounts",
      "404 Page and many more",
      "Responsive Design",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "React Vite",
      "Redux",
      "Redux Toolkit",
      "Tailwind CSS",
      "Apexcharts",
      "Localforage",
      "Match Sorter",
      "Axios",
      "Node JS",
      "Express JS",
      "MySQL",
      "Cors",
      "JWT",
      "Bycrypt",
      "Cookie Parser",
      "Dotenv",
      "MySQL 12",
      "Nodemon",
      "Sequelize",
    ],
    title: "Employee Salary Management",
    description:
      "Warehouse website with employee/employee management, add employee and job title options. Employee attendance data setting salary deductions and salary data. Print salary reports, attendance and salary data and many other features.",
    category: "business",
    // liveLink: "https://berthutapea.vercel.app/employee-salary-management",
    codeLink: "https://github.com/masterdev0218/employee-salary-managment",
  },
  {
    id: 5,
    mainImage: "https://i.ibb.co/3cckJhb/img-1-yhedpu.png",
    img: [
      "https://i.ibb.co/3cckJhb/img-1-yhedpu.png",
      "https://i.ibb.co/Qdk6NKb/img-2-uktrxk.png",
      "https://i.ibb.co/YTDHbJb/img-3-s1js9i.png",
      "https://i.ibb.co/1R4Sj89/img-4-sb9ga6.png",
      "https://i.ibb.co/VvJTmQH/img-5-wrxlr7.png",
    ],
    features: [
      "Add Student",
      "Edit Student",
      "Remove Student",
      "No Student Page",
      "Responsive Design",
      "404 Page and many more",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Router Dom",
      "Axios",
      "Bootstrap",
      "React Icons",
      "Sweet Alert2",
      "Node JS",
      "Express JS",
      "Cors",
      "Mongoose",
      "Dotenv",
      "Nodemon",
      "MongoDB",
    ],
    title: "Student Management System",
    description:
      "Fullstack open source student management system application made with MongoDB, Express, React & Nodejs (MERN)",
    category: "business",
    liveLink: "https://sttiss.vercel.app/",
    codeLink: "https://github.com/masterdev0218/mern-student-management-system",
  },
  {
    id: 6,
    mainImage: "https://i.ibb.co/FY6NdHg/img-1-gpiiro.png",
    img: [
      "https://i.ibb.co/FY6NdHg/img-1-gpiiro.png",
      "https://i.ibb.co/y4j0hYN/img-2-iwduig.png",
      "https://i.ibb.co/SV6PLM4/img-3-sk2hyn.png",
    ],
    features: [
      "Mobile Responsive",
      "Beautiful Animations",
      "Rapid Controls",
      "High Score",
      "Mario Background Music",
      "Jump & Game Over Sound Effect",
    ],
    technologies: [
      "React",
      "CSS",
      "React Hooks",
      "React Router",
      "React Redux",
    ],
    title: "Mario Jump",
    description:
      "Mario Jump is an endless running game inspired by the famous Google Chrome's T-Rex Dinosaur Game 🦖. The game is built entirely with ReactJS ⚛ and Redux Toolkit 🔧.",
    category: "game",
    liveLink: "https://mariojumpp.vercel.app/",
    codeLink: "https://github.com/berthutapea/mario-jump-game",
  },
  {
    id: 7,
    mainImage: "https://i.ibb.co/j4hLxYL/img-1-gqero4.png",
    img: [
      "https://i.ibb.co/j4hLxYL/img-1-gqero4.png",
      "https://i.ibb.co/r7jv1f2/img-2-ukjdh2.png",
      "https://i.ibb.co/vd8V1SS/img-3-t6uxoj.png",
      "https://i.ibb.co/Y7mTSkS/img-4-btevir.png",
      "https://i.ibb.co/4pwZSkp/img-5-oakjx2.png",
      "https://i.ibb.co/7C0KJ4v/img-6-pqefy9.png",
      "https://i.ibb.co/Z24xD1P/img-7-wfrvb7.png",
    ],
    features: [
      "User registration and login",
      "Select the type of question and the difficulty level of the question",
      "Juis time is running and after the time is up it stops automatically",
      "The final results of the answers answered will be shown",
      "Incorrect and correct answer results will be displayed",
      "Responsive Design",
    ],
    technologies: [
      "React",
      "React Vite",
      "Tailwind CSS",
      "Firebase Authentication",
      "Framer Motion",
      "React Firebase Hooks",
      "React Icons",
    ],
    title: "Bert Quiz",
    description:
      "Fullstack open source quiz application",
    category: "personal",
    liveLink: "https://bertquiz.vercel.app/",
    codeLink: "https://github.com/masterdev0218/react-quiz.git",
  },
  {
    id: 8,
    mainImage: "https://i.ibb.co/4tTf1XD/img-1-q5d6pn.png",
    img: [
      "https://i.ibb.co/4tTf1XD/img-1-q5d6pn.png",
      "https://i.ibb.co/J5BC0mS/img-2-nko9ic.png",
      "https://i.ibb.co/J5xpy7b/img-3-rnhbr1.png",
      "https://i.ibb.co/rt9xMkn/img-4-ks8lck.png",
      "https://i.ibb.co/xmDQ40Z/img-6-fgpzef.png",
      "https://i.ibb.co/gMVkvGn/img-5-eotroj.png",
    ],
    
    features: [
      "Mobile Responsive",
      "Beautiful Animations",
      "Product",
      "Pricing",
      "Testimonial",
      "Contact",
    ],

    technologies: [
      "React",
      "React Vite",
      "Tailwind CSS",
      "Framer Motion",
      "React Icons",
      "React Hooks",
    ],
    title: "Shopify",
    description:
      "Open source landing page shopify application",
    category: "personal",
    liveLink: "https://shopifyy.vercel.app/",
    codeLink: "https://github.com/masterdev0218/react-shopify-landing.git",
  },
  {
    id: 9,
    mainImage: "https://i.ibb.co/D4Jt0QT/portfolio7.png",
    img: [
      "https://i.ibb.co/D4Jt0QT/portfolio7.png",
      "https://i.ibb.co/ftS8bC3/portfolio8.png",
      "https://i.ibb.co/vxdwTJX/portfolio6.png",
      "https://i.ibb.co/H2GTV5L/portfolio5.png",
      "https://i.ibb.co/sy9hm9N/portfolio3.png",
      "https://i.ibb.co/WvsB0Jt/portfolio4.png",
      "https://i.ibb.co/F46Gztn/portfolio2.png",
      "https://i.ibb.co/j8M7Wwt/portfolio1.png"
    ],
    features: [
      "Responsive Layout",
      "Shopping Cart, Wishlist, Product Reviews",
      "Coupons & Discounts",
      "Product attributes: cost price, promotion price, stock, size...",
      "Blog: category, tag, content, web page",
      "Module/Extension: Shipping, payment, discount, ...",
      "Upload manager: banner, images,..",
      "SEO support: customer URL b",
      "Newsletter management",
      "Contact forms with the real-time notification (Laravel Pusher)",
      "Related Products, Recommendations for you in our categories",
      "A Product search form",
      "Laravel Socialite implement(Facebook, Google & twitter) & Customer login",
      "Product Share and follow from different social platform...",
      "Payment integration(Paypal)",
      "Order Tracking system",
      "Multi-level comment system"
    ],
    technologies: [
      "Laravel 10",
      "Composer",
      "My SQL",
      "Bootstrap",
      "Html",
      "Css 3",
    ],
    title: "Ecommerce(Laravel 10)",
    description:
      "Open source landing page shopify application",
    category: "personal",
    liveLink: "https://ketramart.com",
    codeLink: "https://github.com/masterdev0218/Ecommerce-in-laravel-10",
  },
  {
    id: 10,
    mainImage: "https://i.ibb.co/fx5mXnH/img-2-ptr4lg.png",
    img: [
      "https://i.ibb.co/fx5mXnH/img-2-ptr4lg.png",
      "https://i.ibb.co/Y3n74DV/img-1-jppjdj.png",
      "https://i.ibb.co/1q6BLNs/img-3-gjle1m.png",
      "https://i.ibb.co/PhfWjcv/img-4-kmaye9.png",
      "https://i.ibb.co/NTxH5dL/img-5-nuhniv.png",
      "https://i.ibb.co/1JKz7F8/img-6-rkvmhd.png",
      "https://i.ibb.co/g3RrTdJ/img-8-dj0qoq.png",
      "https://i.ibb.co/LdQLvdW/img-10-kbbkd4.png",
      "https://i.ibb.co/YbjtFdw/img-11-mmspfs.png"
    ],
    features: [
      "User registration and login",
      "Authentication using JWT Tokens",
      "Story searching and pagination",
      "CRUD operations (Story create, read, update and delete)",
      "Upload user ımages and story ımages to the server",
      "Liking stories and adding stories to the Reading list",
      "Commenting on the story",
      "Skeleton loading effect",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "Axios",
      "CSS",
      "UUID",
      "NodeJS",
      "ExpressJS",
      "MongoDB",
      "Mongoose",
      "Express Asynsc Handler",
      "Json Web Token",
      "Bcryptjs",
      "Nodemailer",
      "Dontenv",
      "Multer",
      "Slugify",
      "Cors",
    ],
    title: "Mern Blog",
    description:
      "Fullstack open source blogging application made with MongoDB, Express, React & Nodejs (MERN)",
    category: "personal",
    // liveLink: "https://berthutapea.vercel.app/mern-blog",
    codeLink: "https://github.com/masterdev0218/mern-blog",
  },  
  {
    id: 11,
    mainImage: "https://i.ibb.co/Cwrvvfs/9.png",
    img: [
      "https://i.ibb.co/Cwrvvfs/9.png",
      "https://i.ibb.co/93Fb87m/1.png",
      "https://i.ibb.co/CbxsN15/2.png",
      "https://i.ibb.co/9tGyYmy/3.png",
      "https://i.ibb.co/wBnwKnN/4.png",
      "https://i.ibb.co/2t5crjx/5.png",
      "https://i.ibb.co/zXrxb0y/6.png",
      "https://i.ibb.co/DLP6sYC/7.png",
      "https://i.ibb.co/8mLFRkx/8.png",
    ],
    features: [
      "Dashboard",
      "Business Setting",
      "System Management",
      "LCBO, Shop&Pay, Food, Mobile App Order",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "Laravel",
      "Css",
      "Flutter",
      "Javascript",
      "My SQL",
    ],
    title: "DDRadar",
    description:
      "This app has Admin, User, Delivery role and is delivery app for customers",
    category: "business",
    liveLink: "https://platform.ddradar.com/",
  },
];

export default Items;
